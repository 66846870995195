<template>
  <div class="modal fade" id="readerModal" tabindex="-1" role="dialog" aria-labelledby="readerModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="readerModalLabel">셀 리더를 선택하세요. </h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <ul class="list-group">
            <li class="list-group-item d-flex justify-content-between align-items-center" v-for="reader in readerList" :key="reader.id">
              <span>{{ reader.name }}</span>
              <button class="btn btn-primary btn-sm" @click="selectReader(reader.no)">선택</button>
            </li>
          </ul>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import manageService from "../services/manageService";
import $ from "jquery";

export default {
  props: ["showModal"],
  data() {
    return {
      readerList: [],
      name: this.$store.state.name,
      teacherid: this.$store.state.teacherid,
      role: this.$store.state.role,
      token: this.$store.state.token,
      classid: this.$store.state.classid,      
    };
  },
  methods: {

    fetchReaderList() {

      const ndata = {
        classid: this.classid,
        grade: this.gradegroup,
        role: this.role,
      };

      manageService.getCellReaderList(ndata)
        .then((response) => {
          this.readerList = response.data;
          //console.log('readerList',  this.readerList);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    selectReader(reader) {
      this.$emit("select-reader", reader);
      $('#readerModal').modal('hide');
    },
  },
  mounted() {
    // Initialize modal event listeners
    $('#readerModal').on('shown.bs.modal', () => {
      this.fetchReaderList();
    });
  },
};
</script>

<style scoped>
/* Add your styles here */
</style>
